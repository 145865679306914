<template>
  <div class="books-box">
    <ul class="books-list">
      <el-row :gutter="22">
        <el-col :md="6" v-for="item in result" :key="item.id">
          <li class="books-item">
            <div class="whole">
              <router-link :to="{ path: detailurl, query: { id: item.id } }">
                <div class="whole-tou">
                  <img
                    v-if="item.theLocal"
                    :src="`/jeecg-boot/${item.theLocal}`"
                    class="book-image"
                  />
                  <img v-else src="@/assets/image/main/zanwutp.png" alt="" class="imgleft"/>
                </div>
                <div class="whole-jiao">
                  <span class="zz lf">{{ item.theTitle }}</span>
                  <span class="xia"> >> </span>
                </div>
              </router-link>
            </div>
          </li>
        </el-col>
      </el-row>
    </ul>
    <Pagi
      :total="pagi.total"
      :current="pagi.current"
      :sizes="pagi.size"
      @pagicurrent="togglePagi"
    />
  </div>
</template>

<script>
import Pagi from "@/components/Pagi";
import { getAction } from "@/api/manage";
export default {
  props: {
    urllist: {
      type: String,
    },
    detailurl: {
      type: String,
    },
    param: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    Pagi,
  },
  created() {
    this.loadData(1);
  },
  data() {
    return {
      result: [],
      pagi: {
        total: 0,
        current: 1,
        size: 10,
      },
      url: {
        list: this.urllist,
      },
      queryparam: this.param,
    };
  },
  methods: {
    loadData(value) {
      let params = Object.assign(this.queryparam, {
        pageNo: value,
        pageSize: 12,
      });
      getAction(this.url.list, params).then((res) => {
        if (res.success) {
          this.result = res.result.records;
          this.pagi.total = res.result.total;
          this.pagi.current = res.result.current;
          this.pagi.size = res.result.size;
        }
      });
    },
    togglePagi(value) {
      this.loadData(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.books-box {
  padding: 20px 18px 50px;
  .books-list {
    min-height: 500px;
    .books-item {
        width: 276px;
      .whole {
        border: solid 1px #dbdbdb;
        transition: all 0.3s;
        width: 276px;
        height: 285px;
        overflow: hidden; 
        .whole-tou {
            overflow: hidden; 
          .book-image {
            width: 276px;
            height: 200px;
            cursor: pointer;
				transition: all 0.6s;
            &:hover {
				transform: scale(1.2);
            }
          }
        }
      }
      .whole-jiao {
        margin-top: 20px;
        .zz {
          font-size: 18px;
          line-height: 29px;
          color: #333333;
          margin-left: 12px;
          width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .xia {
          float: right;
          margin-top: 7px;
          margin-right: 15px;
        }
      }
      &:hover {
        box-shadow: 0px 2px 5px 0px rgba(42, 74, 128, 0.13);
        background-color: #ffffff;
      }
    }
  }
}

.el-col-24 {
  margin-bottom: 20px;
}
</style>
